<template>
  <div class="posteredit">
    <div class="posew">
      <div class="bgshow">
        <div class="bgimg" @mousemove="moveitem">
          <img v-if="bgimg!=''" class="posterbg" :src="bgimg" />
          <template v-for="(p,k) in item">
            <div :class="p.type+(selkey==k?' cur':'')" :key="k" v-if="p.type=='head'" :style="{'width':(p.size+2)+'px','height':(p.size+2)+'px','left':p.left+'px','top':p.top+'px'}" tabindex="-1" @blur="selkey=-1" @keydown="sete($event,k)" @mousedown="selitem($event,k)" @mouseup="selkey=-1">
              <div class="headimg" :style="{'backgroundImage': 'url('+ApiUrl+'/static/appimg/user1.png)'}"></div>
            </div>
            <div :class="p.type+(selkey==k?' cur':'')" :key="k" v-else-if="p.type=='name'" :style="{'fontSize':p.fontSize+'px','color':p.color,'left':p.left+'px','top':p.top+'px'}" tabindex="-1" @blur="selkey=-1" @keydown="sete($event,k)" @mousedown="selitem($event,k)" @mouseup="selkey=-1">用户昵称</div>
            <div class="iconfont" :class="p.type+(selkey==k?' cur':'')" :key="k" v-else-if="p.type=='qrcode'" :style="{'fontSize':p.size+'px','color':p.color,'left':p.left+'px','top':p.top+'px'}" tabindex="-1" @blur="selkey=-1" @keydown="sete($event,k)" @mousedown="selitem($event,k)" @mouseup="selkey=-1">&#xe680;</div>
          </template>
        </div>
        <div class="sizetips">背景图尺寸：宽690px 高1424px</div>
      </div>
      <div class="actw">
        <div class="actlis">
          <div class="acttit">背景图片</div>
          <upload class="bgubtn" action="upload_img.html?w=690&h=1424" @upSuccess="upSuccess"></upload>
        </div>
        <div class="actlis">
          <div class="acttit">海报元素</div>
          <div class="yslsw">
            <a class="yslis" href="javascript:void(0)" @click="additem('head')"><Icon type="md-add" />头像</a>
            <a class="yslis" href="javascript:void(0)" @click="additem('name')"><Icon type="md-add" />昵称</a>
            <a class="yslis" href="javascript:void(0)" @click="additem('qrcode')"><Icon type="md-add" />二维码</a>
          </div>
        </div>
        <div class="setitemw" v-if="setkey!=-1">
          <div class="setbs" v-if="settype=='head'">
            <div class="setlis">
              <div class="acttit">头像大小</div>
              <Input class="setva" type="number" step="1" v-model="item[setkey].size" @input="inputv" />
            </div>
          </div>
          <div class="setbs" v-else-if="settype=='name'">
            <div class="setlis">
              <div class="acttit">字体大小</div>
              <Input class="setva" type="number" step="1" v-model="item[setkey].fontSize" @input="inputv" />
            </div>
            <div class="setlis">
              <div class="acttit">字体颜色</div>
              <ColorPicker format="rgb" v-model="item[setkey].color" @on-change="color" />
            </div>
          </div>
          <div class="setbs" v-else-if="settype=='qrcode'">
            <div class="setlis">
              <div class="acttit">二维码大小</div>
              <Input class="setva" type="number" step="1" v-model="item[setkey].size" @input="inputv" />
            </div>
            <div class="setlis">
              <div class="acttit">二维码颜色</div>
              <RadioGroup class="setva radiog" v-model="item[setkey].color">
                <Radio label="black">黑色</Radio>
                <Radio label="white">白色</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
<!--        <div class="actlis">
          <div class="acttit">海报状态</div>
          <div class="actntxt">{{status?'启用':'停用'}}</div>
        </div>-->
        <div class="acttips">提示：海报元素选中后按 “Delete” 键可删除；可使用键盘方向键调整海报元素位置或用鼠标拖动</div>
      </div>
    </div>
    <div class="edsave"><Button type="info" style="width: 120px;" @click="savePoster">提交保存</Button></div>
    <Spin size="large" fix v-if="page_loading"></Spin>
  </div>
</template>
<script>
import upload from "@/components/public/upload";
export default {
  name: 'PosterEdit',
  components:{upload},
  data(){
    return {
      id:'',
      bgimg:'',
      item:[],
      status:0,
      page_loading:false,
      ApiUrl:this.ApiUrl,
      defaultSet: {
        'head': {'size': 75,'left':1,'top':1},
        'name': {'fontSize': 16, 'color': 'rgba(0,0,0,1)','left':135,'top':1},
        'qrcode': {'size': 85, 'color':"black",'left':257,'top':1},
      },
      selkey:-1,
      setkey:-1,
      settype:'',
      screenX:0,
      screenY:0,
      yleft:0,
      ytop:0
    }
  },
  activated() {
    this.id = this.$route.query.id;
  },
  watch:{
    id(){
      if (this.id){this.init();}
    }
  },
  methods:{
    init:function (){
      this.setkey=-1;
      var _this=this;
      this.page_loading=true;
      this.requestApi('/adm/poster_dtl.html',{'id':this.id}).then(function (res){
        _this.page_loading=false;
        if(res.status==1){
          _this.bgimg=res.data.bgimg;
          _this.item=res.data.item;
          _this.status=res.data.status;
        }
      })
    },
    /**
     * 保存海报
     */
    savePoster:function (){
      if(this.bgimg=='') return this.alertError('请上传海报背景图！');
      var _this=this,data={'id':this.id,'bgimg':this.bgimg,'item':this.item,'status':this.status};
      this.page_loading=true;
      this.requestApi('/adm/poster_save.html',data).then(function (res){
        _this.page_loading=false;
        if(res.status==1){
          _this.opentags('/posteredit',{id:res.id});
          _this.tipSuccess(res.msg);
        }else{
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 新增海报元素
     */
    additem:function (type){
      if(this.item.length>=9){
        return this.alertError('请不要添加太多海报元素(限制9个)');
      }
      var item={};
      if(type=='head'){
        item={'type':'head','size':this.defaultSet.head.size,'left':this.defaultSet.head.left,'top':this.defaultSet.head.top};
      }else if(type=='name'){
        item={'type':'name','fontSize':this.defaultSet.name.fontSize,'color':this.defaultSet.name.color,'left':this.defaultSet.name.left,'top':this.defaultSet.name.top};
      }else if(type=='qrcode'){
        item={'type':'qrcode','size':this.defaultSet.qrcode.size,'color':this.defaultSet.qrcode.color,'left':this.defaultSet.qrcode.left,'top':this.defaultSet.qrcode.top};
      }
      this.item.push(item);
    },
    /**
     * 海报元素键盘事件(上下左右移动 / 删除)
     */
    sete:function (event,key){
      let type=this.item[key].type;
      if(event.keyCode==38){//上移
        if(this.item[key].top<=0) this.item[key].top=0;
        else this.item[key].top--;
      }else if(event.keyCode==40){//下移
        let maxt=type=='name'?(712-2-this.item[key].fontSize*2):(712-2-this.item[key].size);
        if(this.item[key].top>=maxt) this.item[key].top=maxt;
        else this.item[key].top++;
      }else if(event.keyCode==37){//左移
        if(this.item[key].left<=0) this.item[key].left=0;
        else this.item[key].left--;
      }else if(event.keyCode==39){//右移
        let maxl=type=='name'?(345-2-this.item[key].fontSize*4):(345-2-this.item[key].size);
        if(this.item[key].left>=maxl) this.item[key].left=maxl;
        else this.item[key].left++;
      }else if(event.keyCode==46){//删除
        this.item.splice(key,1);
        this.setkey=-1;
      }
    },
    /**
     * 选中海报元素
     */
    selitem:function (event,key){
      var _this=this;
      setTimeout(function (){
        _this.selkey=key;
        _this.setkey=key;
        _this.settype=_this.item[key].type;
        _this.screenX=event.screenX;
        _this.screenY=event.screenY;
        _this.yleft=_this.item[key].left;
        _this.ytop=_this.item[key].top;
      },50)
    },
    /**
     * 拖动海报元素
     */
    moveitem:function (event){
      if(this.selkey!=-1){
        let x=event.screenX-this.screenX,
            y=event.screenY-this.screenY;
        let type=this.item[this.selkey].type,
            maxt=type=='name'?(712-2-this.item[this.selkey].fontSize*2):(712-2-this.item[this.selkey].size),
            maxl=type=='name'?(345-2-this.item[this.selkey].fontSize*4):(345-2-this.item[this.selkey].size),
            left=this.yleft+x,
            top=this.ytop+y;
        if(left<=0) left=0;
        else if(left>=maxl) left=maxl;
        if(top<=0) top=0;
        else if(top>=maxt) top=maxt;
        this.item[this.selkey].left=left;
        this.item[this.selkey].top=top;
      }
    },
    /**
     * 上传背景图成功
     */
    upSuccess:function(e){
      this.bgimg = e.response.url;
    },
    /**
     * 海报元素设置过滤
     */
    inputv:function (va){
      var _this=this;
      if(this.settype=='name'){
        let size=parseInt(Math.abs(va))>64?64:parseInt(Math.abs(va));
        this.$nextTick(function (){
          _this.item[_this.setkey].fontSize=size;
        });
      }else{
        let size=parseInt(Math.abs(va))>276?276:parseInt(Math.abs(va));
        this.$nextTick(function (){
          _this.item[_this.setkey].size=size;
        });
      }
      let maxl=this.settype=='name'?(345-2-this.item[this.setkey].fontSize*4):(345-2-this.item[this.setkey].size);
      if(this.item[this.setkey].left>=maxl) this.item[this.setkey].left=maxl;
    },
    /**
     * 设置昵称字体颜色
     */
    color:function (color){
      if(color == '') this.item[this.setkey].color=this.defaultSet.name.color;
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/poster";
</style>